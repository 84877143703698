@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: "Inter";
	text-align: start;
}

.gradient-blue {
	@apply bg-gradient-to-r from-[#4069E9] to-[#6297FF];
}

.blue-gradient-text {
	@apply bg-gradient-to-r from-[#4069e9] to-[#6297ff] bg-clip-text text-transparent;
}

.center-vertical {
	@apply flex flex-col justify-center items-center;
}

.center {
	@apply flex flex-col items-center justify-center;
}

.scale-animation {
	@apply hover:scale-105 transition-transform ease-in-out duration-300;
}

.text-animation {
	@apply text-sm sm:text-2xl w-full font-semibold text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-20 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out;
}

.input {
	@apply appearance-none text-xs sm:text-sm relative block w-full px-3 py-2.5 border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 hover:border-black;
}

.input-label {
	@apply text-xs sm:text-sm text-gray-600 block text-start
}

.input-suggestion {
	@apply text-xs text-gray-600 text-start mt-2
}

.simple-button {
	@apply px-4 py-2 text-blue-600 border border-blue-600 rounded-full hover:bg-blue-50 transition-colors
}

.gradient-button {
	@apply px-4 py-2 gradient-blue text-white rounded-full
}

/*  scrollbar  */
/* Light Mode Scrollbar */
*::-webkit-scrollbar {
	height: 4px;
	width: 4px;
}

*::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: #f9fafb;
}

*::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #b8c0c2;
}

/* Dark Mode Scrollbar */
.dark *::-webkit-scrollbar-track {
	background-color: #2d2d2d;
}

.dark *::-webkit-scrollbar-thumb {
	background-color: #5e5e5e;
}

.ql-toolbar {
	@apply rounded-t-lg
}

.ql-container {
	@apply rounded-b-lg min-h-40
}

iframe::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

iframe {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}
