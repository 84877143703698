/* .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    position: absolute;
    top: 0;
    content: '';
  }
  
  .jimu-primary-loading:before {
    left: -19.992px;
  }
  
  .jimu-primary-loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after,
  .jimu-primary-loading {
    background: #076fe5;
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
  }
  
  .jimu-primary-loading {
    text-indent: -9999em;
    margin: auto;
    position: absolute;
    right: calc(50% - 6.8px);
    top: calc(50% - 16px);
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
  }
  
  @-webkit-keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #076fe5;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #076fe5;
      height: 40px;
    }
  }
  
  @keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #076fe5;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #076fe5;
      height: 40px;
    }
  } */

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .loader {
      @apply relative flex justify-between w-[108px];
    }
  
    .loader::after,
    .loader::before {
      content: "";
      @apply inline-block w-12 h-12 bg-white rounded-full;
      background-image: radial-gradient(circle 14px, #0d161b 100%, transparent 0);
      background-repeat: no-repeat;
      animation: eyeMove 5s infinite, blink 5s infinite;
      border: 2px solid gray;
    }
  }
  
  @keyframes eyeMove {
    0%, 10% {
      background-position: 0px 0px;
    }
    13%, 40% {
      background-position: -15px 0px;
    }
    43%, 70% {
      background-position: 15px 0px;
    }
    73%, 90% {
      background-position: 0px 15px;
    }
    93%, 100% {
      background-position: 0px 0px;
    }
  }
  
  @keyframes blink {
    0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
      height: 48px;
    }
    11%, 21%, 41%, 61%, 71%, 91%, 99% {
      height: 18px;
    }
  }